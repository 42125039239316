<template>
  <page-layout>
    <span slot="header-name">{{ $tf('common.watch_lists') }}</span>
    <div slot="header-actions">
      <el-button
        name="create-btn"
        class="header-button"
        circle
        icon="el-icon-plus"
        size="small"
        @click="$router.push({ path: '/watch-lists/create/' })"
        :disabled="$hasNoPermission('ffsecurity.add_watchlist')"
      />
      <confirm-button
        :header="$tf('delete_all_watch_lists_confirm | it_may_take_some_time')"
        :title="$tfo('delete | all | cards')"
        type="default"
        @confirm="deleteAllCards"
        :disabled="state.loading || $hasNoPermission('ffsecurity.delete_watchlist')"
      >
      </confirm-button>
    </div>
    <el-table
      slot="content"
      name="watch-lists-table"
      class="watch-lists-table table-medium"
      stripe
      :size="$vars.sizes.table || $vars.sizes.common"
      :data="state.items"
      row-key="id"
      @row-click="rowClick"
    >
      <el-table-column prop="id" :label="$tf('common.id')" width="60"></el-table-column>
      <el-table-column prop="" :label="$tf('common.label')" width="120">
        <template slot-scope="{ row }">
          <div class="watch-lists--label pad--1" :style="{ backgroundColor: '#' + row.color }"></div>
        </template>
      </el-table-column>
      <el-table-column :label="$tf('common.name')">
        <template slot-scope="{ row }">
          <router-link name="item-btn" :to="{ path: getItemLink(row) }">{{ row.name }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="faces" :label="$tf('common.faces')" width="120"> </el-table-column>
      <el-table-column prop="active" :label="$tf('common.active,,1')" width="120" align="center">
        <template slot-scope="{ row }">
          <div @click.stop class="text-center">
            <el-checkbox v-model="row.active" :disabled="row.id < 0" @change="(v) => activeChange(v, row)"> </el-checkbox>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </page-layout>
</template>

<script>
import ConfirmButton from '../confirm/confirm-button';
import PageLayout from '@/components/page/layout';

export default {
  name: 'page-cards',
  components: {
    ConfirmButton,
    PageLayout
  },
  computed: {
    state() {
      return this.$store.state.watch_lists;
    }
  },
  mounted() {
    this.setPage(0);
  },
  methods: {
    rowClick(item) {
      if (this.$store.state.app.key.ctrl) return;
      this.$router.push({ path: this.getItemLink(item) });
    },
    getItemLink(item) {
      let editItemLink = '/watch-lists/' + encodeURIComponent(item.id || item.name) + '/';
      return editItemLink;
    },
    setPage(value) {
      this.state.page = value;
      this.$store.dispatch(this.state.Action.Get);
    },
    activeChange(v, item) {
      this.$store.dispatch(this.state.Action.Update, { id: item.id, active: v }).catch((e) => {
        item.active = !v;
      });
      return false;
    },
    deleteAllCards() {
      this.$store
        .dispatch(this.state.Action.DeleteAllCards)
        .then((v) => {
          this.$notify({
            type: 'success',
            message: this.$tfo('action | success') + '. ' + this.$tfo('deleted,,1') + ' ' + v.deleted_count
          });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    }
  }
};
</script>

<style>
.watch-lists--label {
  border: 1px solid #ccc;
}
</style>
